import { lazy } from 'react';

const DevicesAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: '/devices',
      component: lazy(() => import('./Devices')),
    },
  ],
};

export default DevicesAppConfig;
