import { useState } from 'react';
import Button from '@material-ui/core/Button';
import pandoraAuthService from 'app/services/pandoraAuthService';
import { useDispatch } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import { useHistory } from 'react-router';
import { setUserDataPandora } from 'app/auth/store/userSlice';
import { authRoles } from 'app/auth';
import { showMessage } from 'app/store/fuse/messageSlice';

function PandoraLoginTab(props) {
  const [isWaitingForAuth, setIsWaitingForAuth] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  function showDialog() {
    setIsWaitingForAuth(true);
    pandoraAuthService.onAuthenticatedCallback = (token) => {
      setIsWaitingForAuth(false);
      const tokenData = pandoraAuthService.getTokenData(token);
      if (authRoles.loginForbidden.some((r) => r === tokenData.UserRole)) {
        pandoraAuthService.logout();
        dispatch(
          showMessage({
            message: 'Access denied', // text or html
            autoHideDuration: 6000, // ms
            anchorOrigin: {
              vertical: 'top', // top bottom
              horizontal: 'center', // left center right
            },
            variant: 'error', // success error info warning null
          })
        );
      } else {
        dispatch(setUserDataPandora(token));
        history.push('/');
      }
    };
    pandoraAuthService.login();
  }

  return (
    <div className="w-full">
      {isWaitingForAuth ? (
        <FuseLoading />
      ) : (
        <Button className="w-full my-48" color="primary" variant="contained" onClick={showDialog}>
          Log In/Sign Up with Pandora
        </Button>
      )}
    </div>
  );
}

export default PandoraLoginTab;
