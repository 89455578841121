import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import { authRoles } from 'app/auth';
import PandoraAuthService from 'app/services/pandoraAuthService';

const OauthCallbackConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: '/oauthCallback',
      component: () => {
        PandoraAuthService.handleCallback(window.location.href).then(() => window.close());
        return <FuseSplashScreen />;
      },
    },
  ],
};

export default OauthCallbackConfig;
