import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  ApolloLink,
  concat,
} from '@apollo/client';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { useDispatch } from 'react-redux';
import PandoraAuthService from './services/pandoraAuthService';
import { logoutUser } from './auth/store/userSlice';

function ApolloProviderWrapper({ children }) {
  const dispatch = useDispatch();

  const client = new ApolloClient({
    uri: process.env.REACT_APP_API,
    cache: new InMemoryCache(),
  });

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API,
  });

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${PandoraAuthService.getAccessToken()}` || null,
      },
    }));
    return forward(operation);
  });

  const refreshLink = new TokenRefreshLink({
    isTokenValidOrUndefined: () => {
      const token = PandoraAuthService.getAccessToken();
      const valid = PandoraAuthService.isAuthTokenValid(token);
      return valid;
    },
    fetchAccessToken: () => {
      return PandoraAuthService.refreshToken();
    },
    handleFetch: (accessToken) => {},
    handleResponse: (operation, accessTokenField) => (response) => {
      return { access_token: response?.access_token };
      // here you can parse response, handle errors, prepare returned token to
      // further operations
      // returned object should be like this:
      // {
      //    access_token: 'token string here'
      // }
    },
    handleError: (err) => {
      dispatch(logoutUser());
    },
  });
  const apolloLink = concat(refreshLink, concat(authLink, httpLink));

  client.setLink(apolloLink);
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default ApolloProviderWrapper;
