import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import ExampleConfig from 'app/main/example/ExampleConfig';
import Error404Page from 'app/main/404/Error404Page';
import LoginConfig from 'app/main/login/LoginConfig';
import LogoutConfig from 'app/main/logout/LogoutConfig';
import LogsAppConfig from 'app/main/logs/LogsAppConfig';
import OauthCallbackConfig from 'app/main/oauthCallback/OauthCallbackConfig';
import DevicesAppConfig from 'app/main/devices/DevicesAppConfig';
import SubsystemsAppConfig from 'app/main/subsystems/SubsystemsAppConfig';
import { authRoles } from 'app/auth';

const routeConfigs = [
  ExampleConfig,
  LoginConfig,
  LogoutConfig,
  LogsAppConfig,
  OauthCallbackConfig,
  DevicesAppConfig,
  SubsystemsAppConfig,
];

const routes = [
  // if you want to make whole app auth protected by default change defaultAuth for example:
  // ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
  // The individual route configs which has auth option won't be overridden.
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, authRoles.administrator),
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/example" />,
  },
  {
    path: '/loading',
    exact: true,
    component: () => <FuseLoading />,
  },
  {
    path: '/404',
    auth: authRoles.administrator,
    component: () => <Error404Page />,
  },
  {
    component: () => <Redirect to="/404" />,
  },
];

export default routes;
