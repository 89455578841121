import * as oauth from '@panva/oauth4webapi';
import FuseUtils from '@fuse/utils/FuseUtils';
import jwtDecode from 'jwt-decode';
import AUTH_CONFIG from './pandoraAuthServiceConfig';

class PandoraAuthService extends FuseUtils.EventEmitter {
  onAuthenticatedCallback;

  init() {
    this.handleAuthentication();
  }

  handleAuthentication = () => {
    let accessToken = this.getAccessToken();

    if (!accessToken) {
      this.emit('onNoAccessToken');
      return;
    }

    if (this.isAuthTokenValid(accessToken)) {
      this.emit('onAutoLogin', true);
    } else {
      this.refreshToken().then(() => {
        accessToken = this.isAuthenticated();
        if (this.isAuthTokenValid(accessToken)) {
          this.emit('onAutoLogin', true);
        } else {
          this.emit('onAutoLogout', 'Token expired');
        }
      });
    }
  };

  signInWithToken = () =>
    new Promise((resolve, reject) => {
      const accessToken = this.isAuthenticated();
      if (accessToken) {
        const user = this.getTokenUser(accessToken);
        resolve(user);
      } else {
        this.props.logout();
        resolve();
      }

      return Promise.resolve();
    });

  isAuthenticated = () => {
    return this.getAccessToken();
  };

  prepareTokenCheck = (callback) => {
    window.setTimeout(() => {
      const token = this.getAccessToken();
      if (token) {
        callback(token);
      } else {
        this.prepareTokenCheck(callback);
      }
    }, 1000);
  };

  isAuthTokenValid = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    let decoded;
    try {
      decoded = jwtDecode(accessToken);
    } catch {
      return false;
    }
    if (!decoded) {
      return false;
    }

    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      return false;
    }

    return true;
  };

  getTokenData = (accessToken) => {
    if (!accessToken) {
      return {};
    }

    let decoded;
    try {
      decoded = jwtDecode(accessToken);
    } catch {
      return {};
    }
    if (!decoded) {
      return {};
    }

    return decoded;
  };

  getTokenUser = (accessToken) => {
    const tokenData = this.getTokenData(accessToken);
    const user = {
      role: [tokenData.UserRole],
      from: 'pandora',
      data: {
        displayName: tokenData.sub,
      },
    };
    return user;
  };

  getAccessToken = () => {
    return localStorage.getItem('access_token');
  };

  getClient = () => {
    return {
      client_id: AUTH_CONFIG.clientId,
      client_secret: AUTH_CONFIG.clientSecret,
      token_endpoint_auth_method: 'client_secret_basic',
    };
  };

  replaceHttpToHttps = (str) => {
    if (str.startsWith('http') && !str.startsWith('https')) {
      return `https${str.substring(4)}`;
    }
    return str;
  };

  login = async (callback) => {
    this.clearLocalStorage();

    const { issuer, issuerEndpoint } = AUTH_CONFIG;
    const serverMetadata = await oauth
      .discoveryRequest(new URL(issuerEndpoint))
      .then((response) => oauth.processDiscoveryResponse(new URL(issuer), response));
    const client = this.getClient();
    const { redirectUri } = AUTH_CONFIG;
    serverMetadata.authorization_endpoint = this.replaceHttpToHttps(
      serverMetadata.authorization_endpoint
    );
    serverMetadata.token_endpoint = this.replaceHttpToHttps(serverMetadata.token_endpoint);
    serverMetadata.userinfo_endpoint = this.replaceHttpToHttps(serverMetadata.userinfo_endpoint);

    if (serverMetadata.code_challenge_methods_supported?.includes('S256') !== true) {
      // This example assumes S256 PKCE support is signalled
      // If it isn't supported, random `nonce` must be used for CSRF protection.
      throw new Error();
    }

    const codeVerifier = oauth.generateRandomCodeVerifier();
    const codeChallenge = await oauth.calculatePKCECodeChallenge(codeVerifier);
    const codeChallengeMethod = 'S256';

    // redirect user to as.authorization_endpoint
    const authorizationUrl = new URL(serverMetadata.authorization_endpoint);
    authorizationUrl.searchParams.set('client_id', client.client_id);
    authorizationUrl.searchParams.set('code_challenge', codeChallenge);
    authorizationUrl.searchParams.set('code_challenge_method', codeChallengeMethod);
    authorizationUrl.searchParams.set('redirect_uri', redirectUri);
    authorizationUrl.searchParams.set('response_type', 'code');
    authorizationUrl.searchParams.set('scope', 'spec-charge spec-charge-admin offline_access');
    window.open(authorizationUrl);

    localStorage.setItem('server_metadata', JSON.stringify(serverMetadata));
    localStorage.setItem('code_verifier', JSON.stringify(codeVerifier));

    this.prepareTokenCheck(this.onAuthenticatedCallback);
  };

  handleCallback = async (currentUrl) => {
    const serverMetadata = JSON.parse(localStorage.getItem('server_metadata'));
    const codeVerifier = JSON.parse(localStorage.getItem('code_verifier'));
    const client = this.getClient();

    const params = oauth.validateAuthResponse(
      serverMetadata,
      client,
      new URL(currentUrl),
      oauth.expectNoState
    );
    if (oauth.isOAuth2Error(params)) {
      console.log('error', params);
      throw new Error(); // Handle OAuth 2.0 redirect error
    }

    const response = await oauth.authorizationCodeGrantRequest(
      serverMetadata,
      client,
      params,
      AUTH_CONFIG.redirectUri,
      codeVerifier
    );

    const challenges = oauth.parseWwwAuthenticateChallenges(response);
    if (challenges) {
      challenges.forEach((challenge) => {
        console.log('challenge', challenge);
      });
      throw new Error(); // Handle www-authenticate challenges as needed
    }

    const result = await oauth.processAuthorizationCodeOAuth2Response(
      serverMetadata,
      client,
      response
    );
    if (oauth.isOAuth2Error(result)) {
      console.log('error', result);
      throw new Error(); // Handle OAuth 2.0 response body error
    }

    this.clearLocalStorage();
    localStorage.setItem('access_token', result.access_token);
    localStorage.setItem('refresh_token', result.refresh_token);
  };

  logout = () => {
    this.clearLocalStorage();
    const logoutUrl = `${AUTH_CONFIG.issuerEndpoint}/User/Logout?returnUrl=${window.location.href}`;
    window.location.href = logoutUrl;
  };

  clearLocalStorage = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('server_metadata');
    localStorage.removeItem('code_verifier');
  };

  refreshToken = () => {
    const refreshToken = localStorage.getItem('refresh_token');
    this.clearLocalStorage();
    return fetch(`${AUTH_CONFIG.issuerEndpoint}/connect/token`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: 'refresh_token',
        client_id: AUTH_CONFIG.clientId,
        client_secret: AUTH_CONFIG.clientSecret,
        refresh_token: refreshToken,
      }),
    })
      .then((result) => {
        return result.json();
      })
      .then((result) => {
        if (result.access_token && result.refresh_token) {
          localStorage.setItem('access_token', result.access_token);
          localStorage.setItem('refresh_token', result.refresh_token);
        }
        return result;
      });
  };

  onAuthenticated = (callback) => {
    this.onAuthenticatedCallback = callback;
  };
}

const instance = new PandoraAuthService();

export default instance;
