import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
  {
    id: 'applications',
    title: 'Applications',
    translate: 'APPLICATIONS',
    type: 'group',
    icon: 'apps',
    children: [
      {
        id: 'example-component',
        title: 'Example',
        translate: 'EXAMPLE',
        type: 'item',
        icon: 'whatshot',
        url: '/example',
      },
      {
        id: 'logs',
        title: 'Logs',
        type: 'item',
        url: '/logs',
        icon: 'list',
        exact: true,
      },
      {
        id: 'devices',
        title: 'Devices',
        type: 'item',
        url: '/devices',
        icon: 'devices',
        exact: true,
      },
      {
        id: 'subsystems',
        title: 'Subsystems',
        type: 'item',
        url: '/subsystems',
        icon: 'developer_board',
        exact: true,
      },
    ],
  },
];

export default navigationConfig;
