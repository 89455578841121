import { lazy } from 'react';

const SubsystemsAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: '/subsystems/:subsystemId',
      component: lazy(() => import('./subsystem/Subsystem')),
    },
    {
      path: '/subsystems',
      component: lazy(() => import('./Subsystems')),
    },
  ],
};

export default SubsystemsAppConfig;
